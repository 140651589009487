<template>
  <div class="container">
  </div>
</template>

<script>

export default {
  name: "FavoritesBuyers",
  created() {
    this.$router.push({name: 'FavoriteBuyersApartments'});
  }
};
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
